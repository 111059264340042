.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

h1, h2, h3, h4, h5 {
  width: 100%;
}

h1 {
  text-align: center;
  margin: 30px 0 30px 0;
  font-size: 2.5em;
}

h2 {
  text-align: left;
  margin: 50px 0 20px 0;
  font-size: 1.8em;
}

h3, h4, h5 {
  width: 100%;
  margin: 20px 0 10px 0;
}

p {
  margin: 0 0 5px 0;
}

a {
  text-decoration: none;
  color: #3776c5;
  font-weight: bold;
}

section {
  width: 100%;
}

.content {
  padding: 120px 20px 60px 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
}

.print {
  background-color: #fff;
  color: black;
}

.print h1 {
  color: rgb(101, 45, 145);
  font-family: 'Self Deception';
  font-size: 80px;
}

.print p {
  color: #181818;
  font-family: 'Self Deception';
  font-size: 35px;
}

.MuiTimeline-root {
  width: 800px;
}

.MuiTimelineDot-root {
  margin-top: 17px !important;
}

.MuiTimelineConnector-root {
  margin-top: 5px;
}

.MuiTimeline-root {
  margin-left: -80px;
}