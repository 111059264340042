.language-selector {
    height: 60%;
    display: flex;
    align-items: center;
    margin: 10px;
}

.language-selector__flag {
    height: 30px;
    min-width: 30px;
    margin: 0 5px;
    border: 2px solid transparent;
    cursor: pointer;
}

.language-selector__flag:hover {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}