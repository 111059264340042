.cooperation {
    max-width: 1000px;
}

.cooperation header {
    margin-bottom: 50px;
}

.cooperation p {
    line-height: 1.5em;
    font-size: 17px;
    margin: 0 0 15px 0;
}

.cooperation li {
    line-height: 1.3em;
    font-size: 17px;
    margin: 0 0 5px 0;
}

.cooperation-example__text {
    padding: 0 15px;
}

.cooperation__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cooperation__image-container img {
    width: 100%;
}

.cooperation-example {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0;
}

.cooperation-example__title {
    font-weight: bold;
    font-size: 1.6rem !important;
}

.cooperation-example__column1 {
    flex: 3;
    padding: 15px;
    min-width: 280px;
}

.cooperation-example__column2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-top: 50px;
}

.cooperation-example__year {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.cooperation-example__logo {
    width: 280px;
    padding-bottom: 10px;
}

.cooperation-example__logo img {
    width: 100%;
}

.cooperation-example__oter-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.cooperation-example__oter-images img {
    height: 100px;
}

.cooperation-example__director {
    border-radius: 50%;
    width: 200px;
    padding-bottom: 10px;
}

.cooperation-example__director-name {
    font-weight: bold;
    padding-bottom: 10px;
    text-align: center;
}

.cooperation-example__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cooperation-example__images img {
    width: 30%;
    min-width: 250px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 10px;
}

.cooperation-example__images img:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cooperation-example__cover-image img {
    max-width: 100%;
}

@media screen and (max-width: 768px){
    .cooperation-example__column2 {
        margin-top: 10px;
    }
}