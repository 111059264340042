.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.home > * {
  max-width: 85vw;
  align-self: stretch;
}

.home header {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.home__our-photo {
  width: 100%;
  max-width: 500px;
  margin: 40px 0;
  border: 1px solid white;
  border-radius: 10px;
}

.home__countdown {
  padding-top: 20px;
  text-align: center;
}

.tiles {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
}