.contact {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  width: 100%;
 }

 .contact p {
  line-height: 1.5em;
  font-size: 17px;
}

.contact__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.contact__content > * {
  flex: 1;
  margin: 20px;
}

.contact__title {
  font-weight: bold;
  font-size: 1.6rem !important;
  padding-top: 30px;
  padding-bottom: 20px;
  white-space: nowrap;
}

.contact__email {
  display: flex;
  flex-direction: row;
  padding: 0 0 20px 0;
  align-items: center;
}

.contact__email > img {
  margin-right: 10px;
  height: 30px;
}

.bank-accounts__title {
  padding: 10px 0 0 0;
}

.bank-accounts {
  padding: 0 0 0 30px;
}

.headquarters {
  padding: 10px 0 0 0;
}

.map__container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

@media screen and (max-width: 768px){
  .contact__content {
    display: block;
  }
 .contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 .bank-accounts {
  text-align: center;
  padding: 0;
 }
}