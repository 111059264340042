.about-us {
    max-width: 1000px;
}

.about-us p {
    line-height: 1.5em;
    font-size: 17px;
    margin: 0 0 25px 0;
}

.our-story {
    padding: 0 0 20px 0;
}

.our-story p {
    text-align: justify;
}

.our-story__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.our-story__images img {
    width: 30%;
    min-width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 10px;
}

.our-story__images img:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.our-mission p {
    text-align: justify;
}

.our-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.our-team {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.our-team p {
    margin-bottom: 5px;
}

.our-team__member {
    flex-basis: 15%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.our-team__member img {
    width: 60%;
    border-radius: 50%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.our-team__name {
    font-weight: bold;
}

.our-team__job-title {
    padding-bottom: 10px;
    text-align: center;
}

.our-team__email {
    color: #181818;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
    display: block;
    font-weight: normal;
}