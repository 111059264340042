.footer {
    display: flex;
    flex-direction: row;
    padding: 50px 20px;
    background-color: #d9d9d9;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.footer__logo-container {
    margin: 0 10px;
}

.footer__logo {
    width: 200px;
    margin-top: -28px;
}

.footer__body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.footer__column {
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px 10px;
    justify-content: center;
}

.footer__company-info {
    display: flex;
    flex-direction: column;
}

.footer_company-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.footer__column-text {
    margin: 0 20px;
}

.footer__center-vertically-min-height {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 53px;
}

.footer__column-text--bold {
    font-weight: bold;
}

.footer__column-image-container > img {
    width: 42px;
    margin-top: 5px;
}