.tile {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  flex: 1;
  margin: 10px 20px;
}

.tile__image {
  height: 200px;
  margin-bottom: 20px;
}

.tile__title {
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.tile__title--min-height {
  min-height: 3em;
}

.tile__text {
  text-align: center;
  line-height: 1.5em;
}