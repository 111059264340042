.navigation {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));
}

.navigation__hamburger-icon {
  color: black;
  font-size: 40px;
  padding: 10px;
  line-height: 40px;
  float: right;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
  border-radius: 10px;
}

.navigation__home-icon {
  color: black;
  font-size: 40px;
  padding: 0px;
  line-height: 40px;
  float: right;
  width: 20px;
}

.navigation__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-top: 10px;
  padding: 0;
  right: 0;
}

.navigation__list li {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.navigation__list a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  color: black;
  text-align: left;
  text-decoration: none;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  font-weight: normal;
}

.navigation__list li:first-child>a {
  padding: 22px 20px;
}

.navigation__list a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.navigation__list a:visited {
  color: black;
}

@media screen and (max-width: 768px){
  .navigation--dark {
    background-color: transparent;
  }

  .navigation__hamburger-icon {
    flex: 1;
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }

  .navigation__list {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.5s ease;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .navigation__list--hidden {
    transform: scaleY(0);
  }

  .navigation__list li {
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 60px;
    align-items: center;
  }

  .navigation__list a {
    width: auto;
  }
}

.navigation__login-icon {
  vertical-align: sub;
}