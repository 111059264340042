.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex-grow: 1;
  width: 100%;
}

.map--loading {
  display: none;
}

.map--loaded {
  width: 100%;
  height: 100%;
  min-height: 450px;
  border: 0px solid white;
  border-radius: 30px;
}
